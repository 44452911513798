import { HttpClient, HttpHeaders } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { NgHttpCachingService } from 'ng-http-caching';
import { map } from 'rxjs';
import { ContractTerms } from 'src/app/models/contract-terms';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class ContractTermsService {
  private http = inject(HttpClient);
  private header = new HttpHeaders({
    'Content-Type': 'application/json',
    'X-NG-HTTP-CACHING-ALLOW-CACHE': 'true',
  });
  private ngHttpCachingService = inject(NgHttpCachingService);

  public postContractTerms(contractTerms: ContractTerms) {
    this.ngHttpCachingService.clearCacheByRegex(/contractterms/);
    return this.http.post<ContractTerms>(
      environment.matchMakingApiUrl + '/v1/contractterms',
      contractTerms,
      { headers: this.header }
    );
  }

  public companyIsMissingContractTerms(companyId: string) {
    return this.getOriginalContractTermsForCompany(companyId).pipe(
      map((terms) => !Boolean(terms?.businessConditions))
    );
  }

  public putContractTerms(contractTerms: ContractTerms) {
    this.ngHttpCachingService.clearCacheByRegex(/contractterms/);
    return this.http.put<ContractTerms>(
      environment.matchMakingApiUrl + '/v1/contractterms',
      contractTerms,
      { headers: this.header }
    );
  }

  public adminPutContractTerms(contractTerms: ContractTerms) {
    this.ngHttpCachingService.clearCacheByRegex(/contractterms/);
    return this.http.put<ContractTerms>(
      environment.matchMakingApiUrl + '/v1/admin/contractterms',
      contractTerms,
      { headers: this.header }
    );
  }

  public getContractTerms() {
    return this.http.get<ContractTerms[]>(
      environment.matchMakingApiUrl + '/v1/contractterms/',
      { headers: this.header }
    );
  }

  private mapOriginalTerms(terms: ContractTerms) {
    return {
      ...terms,
      businessConditions: terms.businessConditionsOriginal,
      otherTerms: terms.otherTermsOriginal,
      paymentTerms: terms.paymentTermsOriginal,
    };
  }

  private mapAnonymizedTerms(terms: ContractTerms) {
    return {
      ...terms,
      businessConditions: terms.businessConditionsAnonymized,
      otherTerms: terms.otherTermsAnonymized,
      paymentTerms: terms.paymentTermsAnonymized,
    };
  }

  public getAnonymizedContractTermsForCompany(companyId: string) {
    return this.http
      .get<ContractTerms>(
        environment.matchMakingApiUrl +
          `/v1/contractterms/company/${companyId}`,
        { headers: this.header }
      )
      .pipe(map((terms) => this.mapAnonymizedTerms(terms)));
  }

  public getOriginalContractTermsForCompany(companyId: string) {
    return this.http
      .get<ContractTerms>(
        environment.matchMakingApiUrl +
          `/v1/contractterms/company/${companyId}?showoriginal=true`,
        { headers: this.header }
      )
      .pipe(map((terms) => this.mapOriginalTerms(terms)));
  }

  public getOriginalContractTerms(contractTermsId: string) {
    return this.http
      .get<ContractTerms>(
        environment.matchMakingApiUrl +
          `/v1/contractterms/${contractTermsId}?showoriginal=true`,
        { headers: this.header }
      )
      .pipe(map((terms) => this.mapOriginalTerms(terms)));
  }

  public getAnonymizedContractTerms(contractTermsId: string) {
    return this.http
      .get<ContractTerms>(
        environment.matchMakingApiUrl +
          `/v1/contractterms/${contractTermsId}?showoriginal=false`,
        { headers: this.header }
      )
      .pipe(map((terms) => this.mapAnonymizedTerms(terms)));
  }
}
